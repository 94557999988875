import React from "react";
import styles from "./Header.module.scss";

export default function Header(props) {
  const currTime = props.dateTime.toLocaleTimeString();
  const currDate = props.dateTime.toLocaleDateString();

  return (
    <>
      <header className={styles.header}>
        <img src="/img/logo.svg" alt="Евромед" />
        <div className={styles.data}>
          <div className={styles.dateTime}>
            <div>
              <div className={styles.appClock}>{currTime.substring(0, 5)}</div>
            </div>
          </div>
          <div className={styles.dateDay}>{currDate}</div>
        </div>
      </header>
    </>
  );
}

import letter1 from "../../sound/letter/а.mp3";
import letter2 from "../../sound/letter/б.mp3";
import letter3 from "../../sound/letter/в.mp3";
import letter4 from "../../sound/letter/г.mp3";
import letter5 from "../../sound/letter/д.mp3";
import letter6 from "../../sound/letter/е.mp3";
import letter7 from "../../sound/letter/ё.mp3";
import letter8 from "../../sound/letter/ж.mp3";
import letter9 from "../../sound/letter/з.mp3";
import letter10 from "../../sound/letter/и.mp3";
import letter11 from "../../sound/letter/к.mp3";
import letter12 from "../../sound/letter/л.mp3";
import letter13 from "../../sound/letter/м.mp3";
import letter14 from "../../sound/letter/н.mp3";
import letter15 from "../../sound/letter/о.mp3";
import letter16 from "../../sound/letter/п.mp3";
import letter17 from "../../sound/letter/р.mp3";
import letter18 from "../../sound/letter/с.mp3";
import letter19 from "../../sound/letter/т.mp3";
import letter20 from "../../sound/letter/у.mp3";
import letter21 from "../../sound/letter/ф.mp3";
import letter22 from "../../sound/letter/х.mp3";
import letter23 from "../../sound/letter/ц.mp3";
import letter24 from "../../sound/letter/ч.mp3";
import letter25 from "../../sound/letter/ш.mp3";
import letter26 from "../../sound/letter/щ.mp3";
import letter31 from "../../sound/letter/э.mp3";
import letter32 from "../../sound/letter/ю.mp3";
import letter33 from "../../sound/letter/я.mp3";

import pacient from "../../sound/patient.mp3";
import number from "../../sound/nomer.mp3";
import goThrough from "../../sound/proidite.mp3";
import office from "../../sound/kabinet.mp3";
import window from "../../sound/okno.mp3";
import silence from "../../sound/silence.mp3";

import one from "../../sound/1.mp3";
import two from "../../sound/2.mp3";
import three from "../../sound/3.mp3";
import four from "../../sound/4.mp3";
import five from "../../sound/5.mp3";
import six from "../../sound/6.mp3";
import seven from "../../sound/7.mp3";
import eight from "../../sound/8.mp3";
import nine from "../../sound/9.mp3";
import ten from "../../sound/10.mp3";

import eleven from "../../sound/11.mp3";
import twelve from "../../sound/12.mp3";
import thirteen from "../../sound/13.mp3";
import fourteen from "../../sound/14.mp3";
import fifteen from "../../sound/15.mp3";
import sixteen from "../../sound/16.mp3";
import seventeen from "../../sound/17.mp3";
import eighteen from "../../sound/18.mp3";
import nineteen from "../../sound/19.mp3";

import oneHundred from "../../sound/100.mp3";
import twoHundred from "../../sound/200.mp3";
import threeHundred from "../../sound/300.mp3";
import fourHundred from "../../sound/400.mp3";
import fiveHundred from "../../sound/500.mp3";
import sixHundred from "../../sound/600.mp3";
import sevenHundred from "../../sound/700.mp3";
import eightHundred from "../../sound/800.mp3";
import nineHundred from "../../sound/900.mp3";
import tenHundred from "../../sound/1000.mp3";

import twenty from "../../sound/20.mp3";
import thirty from "../../sound/30.mp3";
import fourty from "../../sound/40.mp3";
import fifty from "../../sound/50.mp3";
import sixty from "../../sound/60.mp3";
import seventy from "../../sound/70.mp3";
import eighty from "../../sound/80.mp3";
import ninety from "../../sound/90.mp3";

const SoundImport = {
  letter1: letter1,
  letter2: letter2,
  letter3: letter3,
  letter4: letter4,
  letter5: letter5,
  letter6: letter6,
  letter7: letter7,
  letter8: letter8,
  letter9: letter9,
  letter10: letter10,
  letter11: letter11,
  letter12: letter12,
  letter13: letter13,
  letter14: letter14,
  letter15: letter15,
  letter16: letter16,
  letter17: letter17,
  letter18: letter18,
  letter19: letter19,
  letter20: letter20,
  letter21: letter21,
  letter22: letter22,
  letter23: letter23,
  letter24: letter24,
  letter25: letter25,
  letter26: letter26,
  letter31: letter31,
  letter32: letter32,
  letter33: letter33,

  pacient: pacient,
  number: number,
  goThrough: goThrough,
  office: office,
  silence: silence,
  window: window,

  one: one,
  two: two,
  three: three,
  four: four,
  five: five,
  six: six,
  seven: seven,
  eight: eight,
  nine: nine,
  ten: ten,

  eleven: eleven,
  twelve: twelve,
  thirteen: thirteen,
  fourteen: fourteen,
  fifteen: fifteen,
  sixteen: sixteen,
  seventeen: seventeen,
  eighteen: eighteen,
  nineteen: nineteen,

  oneHundred: oneHundred,
  twoHundred: twoHundred,
  threeHundred: threeHundred,
  fourHundred: fourHundred,
  fiveHundred: fiveHundred,
  sixHundred: sixHundred,
  sevenHundred: sevenHundred,
  eightHundred: eightHundred,
  nineHundred: nineHundred,
  tenHundred: tenHundred,

  twenty: twenty,
  thirty: thirty,
  fourty: fourty,
  fifty: fifty,
  sixty: sixty,
  seventy: seventy,
  eighty: eighty,
  ninety: ninety,
};

export default SoundImport;

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./CuponTV.module.scss";

import SoundImport from "../Sound/SoundImport";

// let play = false;

export default function CuponTV(props) {
  const [activeClass, setActiveClass] = useState([styles.movementUp]);
  let cabOrWindow;
  props.sounds.data.splice(0, props.sounds.data.length)
  let windowDigits;
  let result = [];
  props.sounds.data.push(props.ticket);
  if(props.visibleCoupon && props.sounds.data[0] === props.ticket){
    props.newResult.map((name) => props.sounds.data.push(name));
    if(props.window && props.checkPlay.val){
      cabOrWindow = props.window.name.split(" ");
      if (cabOrWindow[0] === "Окно") {
        props.sounds.data.push(SoundImport.window);
      } else {
        props.sounds.data.push(SoundImport.office);
      }
      windowDigits = cabOrWindow[1].split("");
      result = props.digits(windowDigits, true);
      result.map((el) => props.sounds.data.push(el));
    }
  }

  const PlayButton = () => {
    let uris = props.sounds.data;
    console.log('props.sounds.data', props.sounds.data);
    console.log('...props.sounds.data', ...props.sounds.data);
    uris.splice(0, 1);
    if (props.visibleCoupon && props.checkPlay.val === true && props.audio.paused) {
      setTimeout(() => {
          let proms = uris.map(uri => fetch(uri).then(r => r.blob()));
          Promise.all(proms).then(blobs => {
            if (props.visibleCoupon && props.checkPlay.val === true && props.audio.paused) {
              if (proms.length) {
                let blob = new Blob(blobs),
                  blobUrl = URL.createObjectURL(blob);
                  props.audio.setAttribute('src', blobUrl);
                  props.audio.play();
                  props.audio.onended = function() {
                    props.checkPlay.val = false;
                  };
              } else {
                console.log('Promise failed: ' + Date.now());
                props.checkPlay.val = false;
              }
              
            }
          });
      }, 1000);
    }
  };

  useEffect(() => {
    let timer = [];
    for (let i = 0; i < activeClass.length; i++) {
      let timeout = setTimeout(() => {
        timer[i] = setActiveClass((previousValue) => {
          let newValue = [...previousValue];
          // update the value of className here
          newValue[i] = styles.movementDown;
          return newValue;
        });
        props.sounds.data.splice(0, props.sounds.data.length)
        props.checkPlay.val = false;
        let timeout2 = setTimeout(() => {
          props.setVisibleCoupon(false);
          clearTimeout(timeout2);
        }, 1000)
        clearTimeout(timeout);
      }, 8000 * (i + 1)); // delay 8s

    }
  }, []);

  return (
    <>
      {activeClass.map((item, index) => (
        <div className={classNames(styles.coupon, item)} key={item + index}>
          <div className={styles.couponContent}>
            <div className={styles.number}>
              {props.ticket ? props.ticket.code : ""}
            </div>
            <div className={styles.window}>
              {props.window ? props.window.name : ""}
            </div>
          </div>
        </div>
      ))}
      {PlayButton()}
    </>
  );
}

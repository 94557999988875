import React from "react";
import Queue from "../components/Pages/Queue";

const Monitor = (props) => {
  return (
    <div className='container'>
      <Queue
        centrifuge={props.centrifuge}
        newQueue={props.newQueue}
        checkPlay={props.checkPlay}
        processed={props.processed}
        all_queue={props.all_queue}
        visibleCoupon={props.visibleCoupon}
        setVisibleCoupon={props.setVisibleCoupon}
        audio={props.audio}
        setAudio={props.setAudio}
      />
    </div>
  );
};

export { Monitor };

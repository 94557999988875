class Queue {
  constructor(...elements) {
    this.elements = [...elements];
  }

  enqueue(...args) {
    this.elements.push(...args);
    return this.elements;
  }

  dequeue(...args) {
    return this.elements.shift(...args);
  }

  peek() {
    return this.elements[0];
  }

  isEmpty() {
    return this.elements.length === 0;
  }

  notEmpty() {
    return this.elements.length !== 0;
  }

  get length() {
    return this.elements.length;
  }

  set length(length) {
    return (this.elements.length = length);
  }
}

export default Queue;

import React from "react";
import styles from "./Header.module.scss";

export default function HeaderMultiMonitor(props) {
  const currTime = props.dateTime.toLocaleTimeString();
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const currDate = props.dateTime.toLocaleDateString('ru-RU', options);

  return (
    <>
      <header className={styles.header}>
        <p className={styles.textDescription}>
          Электронная очередь <br></br>
          на медосмотр
        </p>
        <img className={styles.logo} src="/img/logo.svg" alt="Евромед" />
        <div className={styles.data}>
          <div className={styles.dateTime}>
            <div>
              <div className={styles.appClock}>{currTime.substring(0, 5)}</div>
            </div>
          </div>
          <div className={styles.dateDay}>{currDate}</div>
        </div>
      </header>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Coupon from "../components/Pages/Coupon";

const Ticket = (props) => {
  return (
    <div className='container'>
      <Coupon ticket={props.ticket} />

      <div style={{ display: "none" }}>
        {useEffect(() => {
          return props.getTicket ? window.print() : "";
        }, [])}

        {setTimeout(() => {
          window.location.reload();
        }, 4 * 1000)}
      </div>
    </div>
  );
};

export { Ticket };

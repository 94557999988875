import React from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";

export default function MainButton(props) {
  return (
      <div
        onClick={props.onClick}
        className={classNames(
          styles.button,
          styles.buttonMain,
          styles.buttonBottom,
          styles.animationButton
        )}
      >
        {props.children}
      </div>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import style from "./MainContent.module.scss";
import Button from "../Button/MainButton/index";
import SecondaryButton from "../Button/SecondaryButton";
import classNames from "classnames";
import { Ticket } from "../../pages/ticket";
import axios from "axios";
import Preloader from "../Preloader/index";
import { Markup } from "interweave";

import QueueClass from "../../utils/Queue";

export default function MainContent(props) {
  let { id } = useParams();
  let domain = process.env.REACT_APP_PORT
    ? process.env.REACT_APP_DOMAIN + ':' + process.env.REACT_APP_PORT
    : process.env.REACT_APP_DOMAIN
  const api = process.env.REACT_APP_SCHEMA + domain + "/api/v1.0/";
  const urlRegistry = api + "public-registry/" + id;
  const urlTickets = api + "public-tickets";
  const [registryJson, setRegistryJson] = useState(null);
  const [currentPageData, setCurrentPageData] = useState(null);
  const [prevPagesStack, setPrevPagesStack] = useState([]);
  const [ticket, setTicket] = useState(false);
  const [showTicket, setShowTicket] = useState(false);

  const [getTicket, setGetTicket] = useState(false);

  const buttonFunction = async () => {
    try {
      fetch(urlRegistry)
        .then((response) => response.json())
        .then((data) => {
          setRegistryJson(data.data);
          setCurrentPageData(data.data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    buttonFunction();
  }, []);

  function nextPage(item) {
    if (item.active_childrens.length) {
      setCurrentPageData(item.active_childrens);
      prevPagesStack.push(currentPageData);
    } else {
      props.setAnimation(true);
      if (!getTicket) {
        setGetTicket(true);
        axios.post(urlTickets, { direction_id: item.uuid }).then((response) => {
          setTimeout(function () {
            props.setAnimation(false);
          }, 3 * 1000);
          setTicket(response.data.data.ticket);
          setShowTicket(true);
        });
      }
    }
  }

  function prevPage() {
    setCurrentPageData(prevPagesStack.pop());
  }

  if (ticket) {
    return (
      <Ticket
        ticket={ticket}
        getTicket={getTicket}
        setGetTicket={setGetTicket}
      />
    );
  }
  return (
    <>
      <section className={classNames(style.buttonWrap, style.buttonWrapTop)}>
        {currentPageData
          ? currentPageData.map((item) => {
              return (
                <Button key={item.id} onClick={() => nextPage(item)}>
                  <Markup content={item.title.replace("#", "<br />")} />
                </Button>
              );
            })
          : ""}
      </section>
      {JSON.stringify(registryJson) !== JSON.stringify(currentPageData) ? (
        <div className={style.positionBlock}>
          <SecondaryButton onClick={() => prevPage()}>Назад</SecondaryButton>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

import React from 'react'
import styles from './SecondaryButton.module.scss'
import classNames from "classnames";

export default function secondaryButton(props) {
  return (
    <div
      onClick={props.onClick}
      className={classNames(
        styles.button,
        styles.secondaryButton,
        styles.buttonTop
      )}>
      {props.children}
    </div>
  )
}

import React, {
  useEffect,
  useState,
} from "react";
import styles from "./Queue.module.scss";
import classNames from "classnames";
import Sound from "../../Sound/index";

export default function Queue(props) {
  const [sounds, setSounds] = useState({data: []});
  const [ticket, setTicket] = useState();
  const [window, setWindow] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.newQueue.elements.length !== 0 && !sounds.data.length && props.checkPlay.val === false && props.visibleCoupon === false && props.audio.paused) {
        props.checkPlay.val = true;
        let ticketData = props.newQueue.dequeue();
        setTicket(ticketData.ticket);
        setWindow(ticketData.window);
        props.setVisibleCoupon(true);
      }
    }, 2 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={classNames(styles.split, styles.left)}>
        <div className={styles.contentWrap}>
          <h2 className={classNames(styles.title, styles.titleWhite)}>
            Очередь
          </h2>
          <ul className={styles.list}>
            {props.all_queue
              ? props.all_queue.map((el, index) => (
                  <li key={"all" + index}>{el.code}</li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      <div className={classNames(styles.split, styles.right)}>
        <div className={styles.contentWrap}>
          <h2 className={classNames(styles.title, styles.titleAccent)}>
            Обслуживается
          </h2>
          <ul className={styles.list}>
            {props.processed
              ? props.processed.map((el, index) => (
                  <li key={"process" + index}>
                    {el.code} - <span>{el.window ? el.window.name : ""}</span>
                  </li>
                ))
              : ""}
          </ul>
        </div>
      </div>
      {props.checkPlay.val  ? (
        <Sound
          ticket={ticket}
          window={window}
          visibleCoupon={props.visibleCoupon}
          setVisibleCoupon={props.setVisibleCoupon}
          checkPlay={props.checkPlay}
          // setCheckPlay={setCheckPlay}
          sounds={sounds}
          setSounds={setSounds}
          audio={props.audio}
          setAudio={props.setAudio}
        />
      ) : (
        ""
      )}
    </>
  );
}

import React, { useMemo, useState } from "react";
import "./components/Global/index.scss";
import HeaderMultiMonitor from "./components/HeaderMultiMonitor";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import { MainPage } from "./pages/main";
import { Monitor } from "./pages/monitor";
import { MonitorMulti } from "./pages/monitor-multi";
import { NotFound } from "./pages/NotFound";
import Centrifuge from "centrifuge";
import axios from "axios";
import { useLocation } from "react-router";
import QueueClass from "./utils/Queue";

const newQueue = new QueueClass();
const checkPlay = { val: false };

function App() {
	const location = useLocation();
	const isMonitorMulti = location.pathname.includes("/monitor-multi/");
	const [all_queue, setAllQueue] = useState();
  	const [processed, setProcessed] = useState();
  	const [dateTime, setDateTime] = useState(new Date());
	const [visibleCoupon, setVisibleCoupon] = useState(false);
	const [audio, setAudio] = useState(new Audio());
	let apiDomain = process.env.REACT_APP_DOMAIN;
	let apiUrl = process.env.REACT_APP_SCHEMA + (
		process.env.REACT_APP_PORT
		? process.env.REACT_APP_DOMAIN + ':' + process.env.REACT_APP_PORT
		: process.env.REACT_APP_DOMAIN
	);
	let token = null;
  	token = useMemo(() => getToken(), []);

	function getToken() {
		axios.get(apiUrl + "/api/v1.0/get-jwt-token").then((response) => {
			token = response.data.data;
			centrifuge.setToken(token);
			centrifuge.subscribe("tickets-chat", function (ctx) {
			if ('/monitor/' + ctx.data.registry_id === location.pathname ||
				'/monitor-multi/' + ctx.data.registry_id === location.pathname
			) {
				if (ctx.data.ticket_process) {
				if (ctx.data.ticket_process.ticket) {
					let getInfo = {
					ticket: ctx.data.ticket_process.ticket,
					window: ctx.data.ticket_process.window,
					};
					newQueue.enqueue(getInfo);
				}
				setAllQueue(ctx.data.all_queue);
				setProcessed(ctx.data.processed);
				}
			}
			});
			centrifuge.subscribe('reload-monitor', function(ctx) {
				if ('/monitor/' + ctx.data.registry_id === location.pathname ||
					'/terminal/' + ctx.data.registry_id === location.pathname ||
					'/monitor-multi/' + ctx.data.registry_id === location.pathname
				) {
					setInterval(() => {
					if (newQueue.isEmpty() && checkPlay.val === false && visibleCoupon === false && audio.paused) {
						console.log('reloading...');
						document.location.reload(true)
					}
					}, 1000);
				}
			});
			centrifuge.subscribe('datetime', function(ctx) {
				setDateTime(new Date(ctx.data.datetime))
			});
			centrifuge.connect();
			let interval = setInterval(() => {
				if(centrifuge.isConnected() && location.pathname.match('monitor')) {
					console.log('connecting...');
					let registry_id = location.pathname;
					registry_id = registry_id.replace('/monitor/', '');
					registry_id = registry_id.replace('/terminal/', '');
					registry_id = registry_id.replace("/monitor-multi/", "");
					axios.get(apiUrl + "/api/public/v1.0/connect-new-monitor?registry_id="
						+ registry_id
						+ '&token='
						+ process.env.REACT_APP_API_PUBLIC_TOKEN)
					.then(() => {
						clearInterval(interval);
						console.log('connected');
					});
				}
			}, 1000);
		});

		return token;
	}
	// Создание очереди
	let centrifuge = new Centrifuge(
	  process.env.REACT_APP_SOKETSCHEMA + apiDomain + ":" + process.env.REACT_APP_CENTRIFUGO_PORT + "/connection/websocket"
	);
	return (
		<>
			{/* <div className="container no_print"> */}
			{isMonitorMulti ? <HeaderMultiMonitor dateTime={dateTime} /> : <Header dateTime={dateTime} />}

			<Routes>
				<Route path='/terminal/:id' element={<MainPage />} />
				<Route path='/monitor-multi/:id' element={<MonitorMulti
					newQueue={newQueue}
					checkPlay={checkPlay}
					processed={processed}
					all_queue={all_queue}
					visibleCoupon={visibleCoupon}
					setVisibleCoupon={setVisibleCoupon}
					audio={audio}
					setAudio={setAudio}
				/>} />
				<Route path='/monitor/:id' element={<Monitor
					newQueue={newQueue}
					checkPlay={checkPlay}
					processed={processed}
					all_queue={all_queue}
					visibleCoupon={visibleCoupon}
					setVisibleCoupon={setVisibleCoupon}
					audio={audio}
					setAudio={setAudio}
				/>} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</>
	);
}

export default App;

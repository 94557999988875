import React from "react";
import classNames from "classnames";
import styles from "./Cupon.module.scss";

export default function Cupon(props) {
  return (
    <div className={styles.coupon}>
      <div className={styles.couponContent}>
        <h2>Ваш номер в очереди</h2>
        <div className={styles.number}>{props.ticket.code}</div>
        <div
          className={classNames(
            styles.cupunDirection,
            styles.cupunDirectionTop
          )}
        >
          {props.ticket.text}
        </div>
        <div className={classNames(styles.date, styles.dateTop)}>
          <div className={styles.dateMonth}>
            {new Date(props.ticket.created_at).toLocaleDateString()}
          </div>
          <div className={styles.dateTime}>
            {new Date(props.ticket.created_at)
              .toLocaleTimeString()
              .substring(0, 5)}
          </div>
        </div>
      </div>
    </div>
  );
}

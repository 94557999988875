import React/* , { useState, useEffect } */ from "react";
import SoundImport from "./SoundImport";
// import ThemeContext from "../../context";
import CuponTV from "../CuponTV/index";


const soundParts = {
  letter: {
    а: SoundImport.letter1,
    б: SoundImport.letter2,
    в: SoundImport.letter3,
    г: SoundImport.letter4,
    д: SoundImport.letter5,
    е: SoundImport.letter6,
    ё: SoundImport.letter7,
    ж: SoundImport.letter8,
    з: SoundImport.letter9,
    и: SoundImport.letter10,
    к: SoundImport.letter11,
    л: SoundImport.letter12,
    м: SoundImport.letter13,
    н: SoundImport.letter14,
    о: SoundImport.letter15,
    п: SoundImport.letter16,
    р: SoundImport.letter17,
    с: SoundImport.letter18,
    т: SoundImport.letter19,
    у: SoundImport.letter20,
    ф: SoundImport.letter21,
    х: SoundImport.letter22,
    ц: SoundImport.letter23,
    ч: SoundImport.letter24,
    ш: SoundImport.letter25,
    щ: SoundImport.letter26,
    э: SoundImport.letter31,
    ю: SoundImport.letter32,
    я: SoundImport.letter33,
  },
  word: {
    // Пациент номер А 19 пройдите в кабинет номер 6
    patient: SoundImport.pacient, // Пациент
    number: SoundImport.number, // Номер
    goThrough: SoundImport.goThrough, // Пройдите
    office: SoundImport.office, // кабинет номер
    silence: SoundImport.silence, // тишина
  },
  oneNumber: {
    1: SoundImport.one,
    2: SoundImport.two,
    3: SoundImport.three,
    4: SoundImport.four,
    5: SoundImport.five,
    6: SoundImport.six,
    7: SoundImport.seven,
    8: SoundImport.eight,
    9: SoundImport.nine,
  },
  twoNumber: {
    10: SoundImport.ten,
    11: SoundImport.eleven,
    12: SoundImport.twelve,
    13: SoundImport.thirteen,
    14: SoundImport.fourteen,
    15: SoundImport.fifteen,
    16: SoundImport.sixteen,
    17: SoundImport.seventeen,
    18: SoundImport.eighteen,
    19: SoundImport.nineteen,
  },
  threeNumber: {
    1: SoundImport.oneHundred,
    2: SoundImport.twoHundred,
    3: SoundImport.threeHundred,
    4: SoundImport.fourHundred,
    5: SoundImport.fiveHundred,
    6: SoundImport.sixHundred,
    7: SoundImport.sevenHundred,
    8: SoundImport.eightHundred,
    9: SoundImport.nineHundred,
    10: SoundImport.tenHundred,
  },
  fourthNumber: {
    2: SoundImport.twenty,
    3: SoundImport.thirty,
    4: SoundImport.fourty,
    5: SoundImport.fifty,
    6: SoundImport.sixty,
    7: SoundImport.seventy,
    8: SoundImport.eighty,
    9: SoundImport.ninety,
  },
};

const digits = (codeArr, is_window = null) => {
  const result = [];
  if (codeArr.length) {
    if (is_window === false) {
      const letter = codeArr.splice(0, 1);
      result.push(soundParts.letter[letter[0].toLowerCase()]);
    }

    if (codeArr.length === 4 && codeArr[0] === "1") {
      result.push(soundParts.threeNumber[10]);
      codeArr.splice(0, 1);
    }

    if (codeArr.length === 3) {
      result.push(soundParts.threeNumber[codeArr[0]]);
      codeArr.splice(0, 1);
    }

    if (codeArr.length === 2) {
      if (codeArr[0] !== 0) {
        if (soundParts.twoNumber[codeArr.join("")] !== undefined) {
          result.push(soundParts.twoNumber[codeArr.join("")]);
        } else {
          result.push(soundParts.fourthNumber[codeArr[0]]);
          codeArr.splice(0, 1);
          if (codeArr.length === 1) {
            result.push(soundParts.oneNumber[codeArr[0]]);
          }
        }
      }
    } else {
      result.push(soundParts.oneNumber[codeArr[0]]);
    }

    return result.filter(function (element) {
      return element !== undefined;
    });
  }
};


export default function Sound(props) {

  const isUndefined = () => {
    let ret = null;
    if (props.ticket !== undefined || null) {
      ret = props.ticket.code;
    } else {
      ret = "ф130";
    }
    return ret;
  };

  // const code = props.ticket.ticket.code;
  const code = isUndefined();
  const codeArr = code.split("");
  // const result = [];
  let newResult = [];
  newResult = digits(codeArr, false);
  // newResult = digits(codeArr, false);

  return (
    <>
      {props.checkPlay.val ? (
        <CuponTV
          newResult={newResult}
          ticket={props.ticket}
          window={props.window}
          visibleCoupon={props.visibleCoupon}
          setVisibleCoupon={props.setVisibleCoupon}
          checkPlay={props.checkPlay}
          // setCheckPlay={props.setCheckPlay}
          digits={digits}
          sounds={props.sounds}
          setSounds={props.setSounds}
          audio={props.audio}
          setAudio={props.setAudio}
        />
      ) : (
        ""
      )}
    </>
  );
}

import React, { useState } from 'react'
import Footer from '../components/Footer'
import MainContent from '../components/MainContent'

const MainPage = () => {
    const [animation, setAnimation] = useState(false);
    return (
      <div>
        <MainContent animation={animation} setAnimation={setAnimation} />
        <Footer animation={animation} setAnimation={setAnimation} />
      </div>
    )
  }


  export {MainPage}
import React from 'react'
import classNames from 'classnames'
import styles from './Cupon.module.scss'

export default function CuponPrint(props) {
  
  return (
    <>
    <div className={styles.printCouponWrap}>
      <div className={styles.printCoupon}>
          <h2 className={styles.printQueue}>Ваш номер в очереди</h2>
          <div className={styles.PrintNumber}>{props.ticket.code}</div>
          <div className={styles.printDescription}>{props.ticket.text}</div>
          <div className={classNames(styles.printFooter, styles.printFooterTop)}>
              <div className={classNames(styles.printDataRight)}>{new Date(props.ticket.created_at).toLocaleDateString()}</div>
              <div>{new Date(props.ticket.created_at).toLocaleTimeString()}</div>
          </div>
      </div>
    </div>
     
    </>
  )
}

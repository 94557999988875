import React from 'react';
import classNames from 'classnames';
import styles from './Preloader.module.scss'

export default function Preloader(props) {
  return (
    <div>
      <div className={styles.dotWrap}>
      <div className={styles.dotsBlock}>
        <div className={classNames(styles.rows, styles.rowBetween)}>
          <div className={classNames(styles.dot, styles.dotAccent, styles.two, (props.animation ? styles.dotAnimation : ''))}></div>
          <div className={classNames(styles.dot, styles.dotAccent, styles.five, (props.animation ? styles.dotAnimation : ''))}></div>
          <div className={classNames(styles.dot, styles.dotAccent, styles.eight, (props.animation ? styles.dotAnimation : ''))}></div>

        </div>
        <div className={classNames(styles.rows, styles.rowAround)}>
          <div className={classNames(styles.dot, styles.dotAccent, styles.three, (props.animation ? styles.dotAnimation : ''))}></div>
          <div className={classNames(styles.dot, styles.dotAccent, styles.six, (props.animation ? styles.dotAnimation : ''))}></div>
        </div>
        <div className={classNames(styles.rows, styles.rowBetween)}>
          <div className={classNames(styles.dot, styles.dotWhite, styles.one, (props.animation ? styles.dotAnimation : ''))}></div>
          <div className={classNames(styles.dot, styles.dotWhite, styles.four, (props.animation ? styles.dotAnimation : ''))}></div>
          <div className={classNames(styles.dot, styles.dotWhite, styles.seven, (props.animation ? styles.dotAnimation : ''))}></div>
        </div>
      </div>
    </div>
      </div>
  )
}

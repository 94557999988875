import React from "react";
import styles from "./Footer.module.scss";
import Preloader from "../Preloader/index";
import classNames from "classnames";

export default function Footer(props) {
  return (
    <div>
      <Preloader
        animation={props.animation}
        setAnimation={props.setAnimation}
      />
      {/*<div className={classNames(styles.gerland, styles.gerlandPosition)}>*/}
        {/* <img width="100%" src="/img/garland.png" alt="Герлянда" /> */}
      {/*</div>*/}
      <footer className={styles.footer}>
        <p className={styles.position}>
          Общество с ограниченной ответственностью "Многопрофильный центр
          современной медицины "Евромед"
        </p>
      </footer>
    </div>
  );
}

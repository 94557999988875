import React, {
    useEffect,
    useState,
    useRef
} from "react";
import styles from "./Queue.module.scss";
import classNames from "classnames";
import Sound from "../../Sound/index";

export default function Queue(props) {
    const [sounds, setSounds] = useState({data: []});
    const [ticket, setTicket] = useState();
    const [windowState, setWindowState] = useState();
    const refListProcess = useRef(null);
    const refListQuery = useRef(null);
    const [columnsStyle, setColumnsStyle] = useState({ gridTemplateColumns: '1fr 1fr' });

    useEffect(() => {
        if (!props.all_queue || !props.processed) {
            setColumnsStyle({ gridTemplateColumns: '1fr 1fr' });
            return;
        }

        let newColumnsStyle = '1fr 1fr';
        if (props.all_queue.length > 1.5 * props.processed.length) {
            newColumnsStyle = '1.5fr 1fr';
        } else if (props.processed.length > 1.5 * props.all_queue.length) {
            newColumnsStyle = '1fr 1.5fr';
        }

        setColumnsStyle({ gridTemplateColumns: newColumnsStyle });
    }, [props.all_queue, props.processed]); // Зависимости

    const smoothScroll = (element, duration = 1000, delayAtBottom = 1400) => {
        let start = null;
        let direction = 1; // Начать с прокрутки вниз
        let startScrollTop = element.scrollTop;
        let distance = element.scrollHeight - element.clientHeight - startScrollTop;
        let delayTimer = null;

        const step = (timestamp) => {
            if (!start) start = timestamp;

            let timeElapsed = timestamp - start;
            let progress = Math.min(timeElapsed / duration, 1);

            // Функция ease-in-out
            progress = -0.5 * (Math.cos(Math.PI * progress) - 1);

            let displacement = direction * progress * distance;

            element.scrollTop = startScrollTop + displacement;

            if (timeElapsed < duration) {
                // Анимация ещё не завершена
                requestAnimationFrame(step);
            } else {
                if (direction === 1) {
                    if (delayTimer) {
                        return;
                    }
                    direction = -1; // Изменить направление на прокрутку вверх
                    start = null;
                    startScrollTop = element.scrollHeight - element.clientHeight;
                    distance = startScrollTop;

                    delayTimer = setTimeout(() => {
                        delayTimer = null;
                        requestAnimationFrame(step);
                    }, delayAtBottom);
                } else {
                    return;
                }
            }
        };

        requestAnimationFrame(step);
    };


    useEffect(() => {
        const elementQuery = refListQuery.current;
        const elementProcess = refListProcess.current;
        if (!elementQuery || !elementProcess) {
            return;
        }

        const doScroll = () => {
            smoothScroll(elementQuery, elementQuery.scrollHeight * 2) // Умножаем на 2, чтобы учесть прокрутку в обоих направлениях
            smoothScroll(elementProcess, elementProcess.scrollHeight * 2) // Умножаем на 2, чтобы учесть прокрутку в обоих направлениях
        };

        // Установка интервала
        const intervalId = setInterval(doScroll, 16000); // Прокрутка каждые 16 секунд

        // Очистка интервала
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (props.newQueue.elements.length !== 0 && !sounds.data.length && props.checkPlay.val === false && props.visibleCoupon === false && props.audio.paused) {
                props.checkPlay.val = true;
                let ticketData = props.newQueue.dequeue();

                setTicket(ticketData.ticket);
                setWindowState(ticketData.window);
                props.setVisibleCoupon(true);
            }
        }, 2 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className={styles.containerBody} style={columnsStyle}>
                <div className={classNames(styles.splitLeft, styles.left)} ref={refListQuery}>
                    <div className={styles.contentWrap}>
                        <h2 className={classNames(styles.title, styles.titleWhite)}>
                            Очередь
                        </h2>

                        <ul className={styles.list_query}>
                          {props.all_queue
                            ? props.all_queue.map((el, index) => (
                                <li key={"all" + index}>{el.code}</li>
                              ))
                            : ""}
                        </ul>
                    </div>
                </div>
                <div className={classNames(styles.splitRight, styles.right)} ref={refListProcess}>
                    <div className={styles.contentWrap}>
                        <h2 className={classNames(styles.title, styles.titleAccent)}>
                            Обслуживается
                        </h2>
                        
                        {/*<ul className={styles.list_process}>*/}
                        {/*    <li>*/}
                        {/*        Б6 <img className={classNames(styles.arrow)} src="/img/arrow.svg" alt="стрелочка"/>*/}
                        {/*        <span>Ф8</span>*/}
                        {/*    </li>*/}
                        {/*    <li>*/}
                        {/*        Б7 <img className={classNames(styles.arrow)} src="/img/arrow.svg" alt="стрелочка"/>*/}
                        {/*        <span>Ф9</span>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}

                        <ul className={styles.list_process}>
                            {props.processed && props.processed.map((el, index) => {
                                if (!el.window || el.window?.ringing) {
                                    return (
                                        <li key={"process" + index} className={classNames({ [styles.blinkThreeTimes]: el.window && el.window.ringing })}>
                                            {el.code} <img className={classNames(styles.arrow)} src="/img/arrow.png" alt="кабинет" width="88" height="77"  />
                                            <span>{el.window ? el.window.name : ""}</span>
                                        </li>
                                    );
                                }
                                return null;
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            {props.checkPlay.val ? (
                <Sound
                    ticket={ticket}
                    window={windowState}
                    visibleCoupon={props.visibleCoupon}
                    setVisibleCoupon={props.setVisibleCoupon}
                    checkPlay={props.checkPlay}
                    sounds={sounds}
                    setSounds={setSounds}
                    audio={props.audio}
                    setAudio={props.setAudio}
                />
            ) : (
                ""
            )}
        </>
    );
}

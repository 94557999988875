import React from "react";
import classNames from "classnames";
import styles from "./Coupon.module.scss";
import Cupon from "../../Cupon/index";
import CuponPrint from "../../Cupon/indexPrint";

export default function Coupon(props) {
  return (
    <>
      <div className={styles.noPrint}>
        <div className={styles.containerCoupon}>
          <div className={styles.contentWrap}>
            <div className={styles.couponWrap}>
              <div className={styles.cuponAnimation}>
                <Cupon ticket={props.ticket} />
                <p
                  className={classNames(
                    styles.couponDescription,
                    styles.textAnimation
                  )}
                >
                  Пожалуйста, ожидайте пока ваш номер появится <br />в разделе
                  Обслуживание на экране
                  <br />
                  электронной очереди.
                </p>
              </div>
            </div>
            <img
              className={styles.womanImg}
              src="/img/girl.png"
              alt="Девушка"
            />
          </div>
        </div>
      </div>

      <div className={styles.print}>
        <CuponPrint ticket={props.ticket} />
      </div>
    </>
  );
}
